import { lazy } from 'react';
import { Route, Routes as RouterSwitch, Navigate } from 'react-router-dom';

import RootTemplate from 'components/template/index';
import ProtectedRoute from './protectedRoute';
import TemplateWithoutSidebar from 'components/template/TemplateWithoutSidebar';

const LandingPage = lazy(() => import('components/page/LandingPage'));

const Login = lazy(() => import('components/page/Auth/Login'));
const ErrorPage = lazy(() => import('components/page/ErrorPage'));

const VerificationEmail = lazy(
  () => import('components/page/Auth/ForgotPassword/VerificationEmail')
);
const ForgotPassword = lazy(
  () => import('components/page/Auth/ForgotPassword')
);
const NewPassword = lazy(
  () => import('components/page/Auth/ForgotPassword/NewPassword')
);
const ChangePasswordPassword = lazy(
  () => import('components/page/Auth/ForgotPassword/ChangePassword')
);
const SetupAPIKey = lazy(() => import('components/page/Setup/APIKey'));
const Chatbot = lazy(() => import('components/page/Chatbot'));
const ChatbotDetail = lazy(() => import('components/page/Chatbot/ChatDetail'));
const ChatShare = lazy(() => import('components/page/Chatbot/ChatShare'));
const ChatbotDocument = lazy(
  () => import('components/page/Chatbot/ChatDocument')
);
const Company = lazy(() => import('components/page/Company'));
const CompanyManagement = lazy(
  () => import('components/page/Company/CompanyManagement')
);
const Document = lazy(() => import('components/page/Document'));
const DetailDocument = lazy(
  () => import('components/page/Document/DetailDocument')
);
const DetailFolderDocument = lazy(
  () => import('components/page/Document/DetailFolder')
);
const OrganizationManagement = lazy(
  () => import('components/page/OrganizationManagement')
);
const DetailOrganizationManagement = lazy(
  () => import('components/page/OrganizationManagement/Detail')
);
const CreateOrEditOrganizationManagement = lazy(
  () => import('components/page/OrganizationManagement/CreateOrEdit')
);
const HistoryLogChat = lazy(() => import('components/page/HistoryLogChat'));
const DetailHistoryLogChat = lazy(
  () => import('components/page/HistoryLogChat/DetailHistoryLogChat')
);
const OrganizationInfo = lazy(() => import('components/page/OrganizationInfo'));
const Users = lazy(() => import('components/page/Users'));
const DocumentAdmin = lazy(() => import('components/page/DocumentAdmin'));

const Routes = () => {
  return (
    <RouterSwitch>
      {/* <Route element={<Navigate replace to="/login" />} index /> */}
      <Route element={<LandingPage />} path="/" />

      <Route element={<Login />} path="/login" />
      <Route element={<ForgotPassword />} path="/forgot-password" />
      <Route element={<VerificationEmail />} path="/verification-email" />
      <Route element={<NewPassword />} path="/new-password/:token" />
      <Route element={<ErrorPage />} path="/error-page/:code" />

      <Route element={<ChatShare />} path="/chat-share/:linkToken" />
      <Route path="*" element={<Navigate to="/error-page/404" />} />

      <Route element={<RootTemplate />}>
        <Route exact path="/" element={<ProtectedRoute />}>
          <Route element={<Chatbot />} path="/chatbot" />
          <Route element={<ChatbotDetail />} path="/chatbot/:chatId" />
          <Route element={<ChatbotDocument />} path="/chatbot/docs" />
          <Route element={<Company />} path="/company" />
          <Route element={<CompanyManagement />} path="/company/detail" />
          <Route element={<Document />} path="/document" />
          <Route element={<DetailDocument />} path="/document/detail" />
          <Route
            element={<DetailFolderDocument />}
            path="/document/folder/detail"
          />
          <Route
            element={<CreateOrEditOrganizationManagement />}
            path="/organization-management/:type"
          />
          <Route
            element={<OrganizationManagement />}
            path="/organization-management"
          />
          <Route
            element={<DetailOrganizationManagement />}
            path="/organization-management/detail/:organizationId"
          />
          <Route element={<HistoryLogChat />} path="/history-log" />
          <Route
            element={<DetailHistoryLogChat />}
            path="/history-log/:chatId"
          />
          <Route element={<OrganizationInfo />} path="/organization-info" />
          <Route element={<Users />} path="/users" />
          <Route element={<DocumentAdmin />} path="/document-admin" />
        </Route>
      </Route>
      <Route element={<TemplateWithoutSidebar />}>
        <Route exact path="/" element={<ProtectedRoute />}>
          <Route element={<SetupAPIKey />} path="/api-key" />
        </Route>
      </Route>
      <Route exact path="/" element={<ProtectedRoute />}>
        <Route element={<ChangePasswordPassword />} path="/change-password" />
      </Route>
    </RouterSwitch>
  );
};

export default Routes;
