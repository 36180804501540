export const ArrowLongLeftIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '20'}
    height={props?.height || '24'}
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8332 10.0001H4.1665M4.1665 10.0001L9.99984 15.8334M4.1665 10.0001L9.99984 4.16675"
      stroke={props?.color || '#000000'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const ArrowLeftIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '20'}
    height={props?.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M15 7L10 12L15 17"
        stroke={props?.color || '#000000'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const ArrowRightIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '20'}
    height={props?.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M10 7L15 12L10 17"
        stroke={props?.color || '#000000'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const ArrowUpIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '20'}
    height={props?.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M17 15L12 10L7 15"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const ArrowDownIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '24'}
    height={props?.height || '28'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M7 10L12 15L17 10"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const ChatbotIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '26'}
    height={props?.height || '26'}
    fill="#667085"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-51.2 -51.2 614.42 614.42"
    stroke="#667085"
    strokeWidth="12.8004"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#CCCCCC"
      strokeWidth="4.096127999999999"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <g>
        {' '}
        <g>
          {' '}
          <path d="M266.683,0.008c-135.275,0-245.333,110.059-245.333,245.333c0,43.669,11.797,86.571,34.155,124.416L0.869,497.16 c-1.728,3.989-0.832,8.64,2.261,11.733c2.048,2.027,4.779,3.115,7.552,3.115c1.408,0,2.859-0.277,4.203-0.875l127.403-54.592 c37.824,22.357,80.704,34.133,124.395,34.133c135.275,0,245.333-110.059,245.333-245.333S401.957,0.008,266.683,0.008z M266.683,469.341c-41.557,0-82.325-11.691-117.931-33.813c-2.965-1.835-6.613-2.133-9.835-0.747L30.992,481.032l46.272-107.947 c1.387-3.2,1.088-6.869-0.747-9.835c-22.123-35.584-33.835-76.352-33.835-117.909c0-123.52,100.48-224,224-224s224,100.48,224,224 S390.203,469.341,266.683,469.341z"></path>{' '}
        </g>{' '}
      </g>{' '}
    </g>
  </svg>
);

export const ChatbotActiveIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '26'}
    height={props?.height || '26'}
    fill="#6D51D7"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-51.2 -51.2 614.42 614.42"
    stroke="#6D51D7"
    strokeWidth="12.8004"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#CCCCCC"
      strokeWidth="4.096127999999999"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <g>
        {' '}
        <g>
          {' '}
          <path d="M266.683,0.008c-135.275,0-245.333,110.059-245.333,245.333c0,43.669,11.797,86.571,34.155,124.416L0.869,497.16 c-1.728,3.989-0.832,8.64,2.261,11.733c2.048,2.027,4.779,3.115,7.552,3.115c1.408,0,2.859-0.277,4.203-0.875l127.403-54.592 c37.824,22.357,80.704,34.133,124.395,34.133c135.275,0,245.333-110.059,245.333-245.333S401.957,0.008,266.683,0.008z M266.683,469.341c-41.557,0-82.325-11.691-117.931-33.813c-2.965-1.835-6.613-2.133-9.835-0.747L30.992,481.032l46.272-107.947 c1.387-3.2,1.088-6.869-0.747-9.835c-22.123-35.584-33.835-76.352-33.835-117.909c0-123.52,100.48-224,224-224s224,100.48,224,224 S390.203,469.341,266.683,469.341z"></path>{' '}
        </g>{' '}
      </g>{' '}
    </g>
  </svg>
);

export const GitMergeIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '20'}
    height={props?.height || '20'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M15 18C15 19.6569 16.3431 21 18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15C16.3431 15 15 16.3431 15 18ZM15 18C12.6131 18 10.3239 17.0518 8.63604 15.364C6.94821 13.6761 6 11.3869 6 9M6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9ZM6 9V21"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const DownloadIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '20'}
    height={props?.height || '20'}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 11.5V14.8333C16.5 15.2754 16.3244 15.6993 16.0118 16.0118C15.6993 16.3244 15.2754 16.5 14.8333 16.5H3.16667C2.72464 16.5 2.30072 16.3244 1.98816 16.0118C1.67559 15.6993 1.5 15.2754 1.5 14.8333V11.5M4.83333 7.33333L9 11.5M9 11.5L13.1667 7.33333M9 11.5V1.5"
      stroke="#344054"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const GitMergeActiveIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '20'}
    height={props?.height || '20'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#6D51D7"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M15 18C15 19.6569 16.3431 21 18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15C16.3431 15 15 16.3431 15 18ZM15 18C12.6131 18 10.3239 17.0518 8.63604 15.364C6.94821 13.6761 6 11.3869 6 9M6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9ZM6 9V21"
        stroke="#6D51D7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const DocumentIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '20'}
    height={props?.height || '20'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M19 9V17.8C19 18.9201 19 19.4802 18.782 19.908C18.5903 20.2843 18.2843 20.5903 17.908 20.782C17.4802 21 16.9201 21 15.8 21H8.2C7.07989 21 6.51984 21 6.09202 20.782C5.71569 20.5903 5.40973 20.2843 5.21799 19.908C5 19.4802 5 18.9201 5 17.8V6.2C5 5.07989 5 4.51984 5.21799 4.09202C5.40973 3.71569 5.71569 3.40973 6.09202 3.21799C6.51984 3 7.0799 3 8.2 3H13M19 9L13 3M19 9H14C13.4477 9 13 8.55228 13 8V3"
        stroke={props?.color || '#667085'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const DocumentActiveIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '20'}
    height={props?.height || '20'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#6D51D7"
  >
    <g
      {...props}
      width={props?.width || '20'}
      height={props?.height || '20'}
      id="SVGRepo_bgCarrier"
      strokeWidth="0"
    ></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M19 9V17.8C19 18.9201 19 19.4802 18.782 19.908C18.5903 20.2843 18.2843 20.5903 17.908 20.782C17.4802 21 16.9201 21 15.8 21H8.2C7.07989 21 6.51984 21 6.09202 20.782C5.71569 20.5903 5.40973 20.2843 5.21799 19.908C5 19.4802 5 18.9201 5 17.8V6.2C5 5.07989 5 4.51984 5.21799 4.09202C5.40973 3.71569 5.71569 3.40973 6.09202 3.21799C6.51984 3 7.0799 3 8.2 3H13M19 9L13 3M19 9H14C13.4477 9 13 8.55228 13 8V3"
        stroke="#6D51D7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const OrganizationInfoIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '22'}
    height={props?.height || '22'}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 15V11M11 7H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
      stroke="#667085"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const OrganizationInfoActiveIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '22'}
    height={props?.height || '22'}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 15V11M11 7H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
      stroke="#6D51D7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const UsersMenuIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '22'}
    height={props?.height || '22'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13M16 3.13C16.8604 3.3503 17.623 3.8507 18.1676 4.55231C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89317 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z"
      stroke="#667085"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const UsersMenuActiveIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '24'}
    height={props?.height || '20'}
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 19V17C17 15.9391 16.5786 14.9217 15.8284 14.1716C15.0783 13.4214 14.0609 13 13 13H5C3.93913 13 2.92172 13.4214 2.17157 14.1716C1.42143 14.9217 1 15.9391 1 17V19M23 19V17C22.9993 16.1137 22.7044 15.2528 22.1614 14.5523C21.6184 13.8519 20.8581 13.3516 20 13.13M16 1.13C16.8604 1.3503 17.623 1.8507 18.1676 2.55231C18.7122 3.25392 19.0078 4.11683 19.0078 5.005C19.0078 5.89317 18.7122 6.75608 18.1676 7.45769C17.623 8.1593 16.8604 8.6597 16 8.88M13 5C13 7.20914 11.2091 9 9 9C6.79086 9 5 7.20914 5 5C5 2.79086 6.79086 1 9 1C11.2091 1 13 2.79086 13 5Z"
      stroke="#6D51D7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const FolderMenuIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '22'}
    height={props?.height || '20'}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 17C21 17.5304 20.7893 18.0391 20.4142 18.4142C20.0391 18.7893 19.5304 19 19 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H8L10 4H19C19.5304 4 20.0391 4.21071 20.4142 4.58579C20.7893 4.96086 21 5.46957 21 6V17Z"
      stroke="#667085"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const FolderMenuActiveIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '22'}
    height={props?.height || '20'}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 17C21 17.5304 20.7893 18.0391 20.4142 18.4142C20.0391 18.7893 19.5304 19 19 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H8L10 4H19C19.5304 4 20.0391 4.21071 20.4142 4.58579C20.7893 4.96086 21 5.46957 21 6V17Z"
      stroke="#6D51D7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const KeyIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '18'}
    height={props?.height || '18'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7071 2.29289C22.0976 2.68342 22.0976 3.31658 21.7071 3.70711L21.1642 4.24999L22.789 5.87475C23.57 6.6558 23.57 7.92213 22.789 8.70317L20.3126 11.1796C19.5315 11.9606 18.2652 11.9606 17.4842 11.1796L15.8594 9.55482L12.7489 12.6653C13.5356 13.7403 14 15.0659 14 16.5C14 20.0899 11.0899 23 7.5 23C3.91015 23 1 20.0899 1 16.5C1 12.9101 3.91015 10 7.5 10C8.9341 10 10.2597 10.4644 11.3347 11.2511L20.2929 2.29289C20.6834 1.90237 21.3166 1.90237 21.7071 2.29289ZM17.2736 8.14061L18.8984 9.76537L21.3748 7.28896L19.75 5.6642L17.2736 8.14061ZM7.5 12C5.01472 12 3 14.0147 3 16.5C3 18.9853 5.01472 21 7.5 21C9.98528 21 12 18.9853 12 16.5C12 14.0147 9.98528 12 7.5 12Z"
        fill="#000000"
      ></path>{' '}
    </g>
  </svg>
);

export const PadLockIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '18'}
    height={props?.height || '18'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.66667 7.33331V4.66665C3.66667 3.78259 4.01786 2.93475 4.64298 2.30962C5.2681 1.6845 6.11595 1.33331 7 1.33331C7.88406 1.33331 8.7319 1.6845 9.35702 2.30962C9.98214 2.93475 10.3333 3.78259 10.3333 4.66665V7.33331M2.33333 7.33331H11.6667C12.403 7.33331 13 7.93027 13 8.66665V13.3333C13 14.0697 12.403 14.6666 11.6667 14.6666H2.33333C1.59695 14.6666 1 14.0697 1 13.3333V8.66665C1 7.93027 1.59695 7.33331 2.33333 7.33331Z"
      stroke="#344054"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LogOutIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '18'}
    height={props?.height || '18'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform="rotate(180)"
    stroke="#000000"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M14 4L18 4C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H14M3 12L15 12M3 12L7 8M3 12L7 16"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const EyeIcon = (props) => (
  <svg
    {...props}
    fill="#000000"
    height={props?.height || '25'}
    width={props?.width || '25'}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    enableBackground="new 0 0 512 512"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <g>
        {' '}
        <path d="m494.8,241.4l-50.6-49.4c-50.1-48.9-116.9-75.8-188.2-75.8s-138.1,26.9-188.2,75.8l-50.6,49.4c-11.3,12.3-4.3,25.4 0,29.2l50.6,49.4c50.1,48.9 116.9,75.8 188.2,75.8s138.1-26.9 188.2-75.8l50.6-49.4c4-3.8 11.7-16.4 0-29.2zm-238.8,84.4c-38.5,0-69.8-31.3-69.8-69.8 0-38.5 31.3-69.8 69.8-69.8 38.5,0 69.8,31.3 69.8,69.8 0,38.5-31.3,69.8-69.8,69.8zm-195.3-69.8l35.7-34.8c27-26.4 59.8-45.2 95.7-55.4-28.2,20.1-46.6,53-46.6,90.1 0,37.1 18.4,70.1 46.6,90.1-35.9-10.2-68.7-29-95.7-55.3l-35.7-34.7zm355,34.8c-27,26.3-59.8,45.1-95.7,55.3 28.2-20.1 46.6-53 46.6-90.1 0-37.2-18.4-70.1-46.6-90.1 35.9,10.2 68.7,29 95.7,55.4l35.6,34.8-35.6,34.7z"></path>{' '}
      </g>{' '}
    </g>
  </svg>
);

export const EyeSlashIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    height={props?.height || '25'}
    width={props?.width || '25'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const PlusCircleIcon = (props) => (
  <svg
    {...props}
    height={props?.height || '20'}
    width={props?.width || '20'}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke={props?.color || '#ffffff'}
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <g stroke={props?.color || '#ffffff'} strokeWidth="2">
        {' '}
        <path strokeLinecap="round" d="M12 16L12 8M16 12L8 12"></path>{' '}
        <path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"></path>{' '}
      </g>{' '}
    </g>
  </svg>
);

export const ArrowLongRightIcon = (props) => (
  <svg
    {...props}
    height={props?.height || '20'}
    width={props?.width || '20'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke={props?.color || '#ffffff'}
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4697 5.46967C13.7626 5.17678 14.2374 5.17678 14.5303 5.46967L20.5303 11.4697C20.8232 11.7626 20.8232 12.2374 20.5303 12.5303L14.5303 18.5303C14.2374 18.8232 13.7626 18.8232 13.4697 18.5303C13.1768 18.2374 13.1768 17.7626 13.4697 17.4697L18.1893 12.75H4C3.58579 12.75 3.25 12.4142 3.25 12C3.25 11.5858 3.58579 11.25 4 11.25H18.1893L13.4697 6.53033C13.1768 6.23744 13.1768 5.76256 13.4697 5.46967Z"
        fill={props?.color || '#ffffff'}
      ></path>{' '}
    </g>
  </svg>
);

export const GlobeInternetIcon = (props) => (
  <svg
    {...props}
    height={props?.height || '20'}
    width={props?.width || '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3337 10.0003C18.3337 14.6027 14.6027 18.3337 10.0003 18.3337M18.3337 10.0003C18.3337 5.39795 14.6027 1.66699 10.0003 1.66699M18.3337 10.0003H1.66699M10.0003 18.3337C5.39795 18.3337 1.66699 14.6027 1.66699 10.0003M10.0003 18.3337C12.0847 16.0517 13.2693 13.0903 13.3337 10.0003C13.2693 6.91035 12.0847 3.94895 10.0003 1.66699M10.0003 18.3337C7.91593 16.0517 6.73137 13.0903 6.66699 10.0003C6.73137 6.91035 7.91593 3.94895 10.0003 1.66699M1.66699 10.0003C1.66699 5.39795 5.39795 1.66699 10.0003 1.66699"
      stroke="#6D51D7"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UserIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '16'}
    height={props?.height || '18'}
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6668 16.5V14.8333C14.6668 13.9493 14.3156 13.1014 13.6905 12.4763C13.0654 11.8512 12.2176 11.5 11.3335 11.5H4.66683C3.78277 11.5 2.93493 11.8512 2.30981 12.4763C1.68469 13.1014 1.3335 13.9493 1.3335 14.8333V16.5M11.3335 4.83333C11.3335 6.67428 9.84111 8.16667 8.00016 8.16667C6.15921 8.16667 4.66683 6.67428 4.66683 4.83333C4.66683 2.99238 6.15921 1.5 8.00016 1.5C9.84111 1.5 11.3335 2.99238 11.3335 4.83333Z"
      stroke="#6D51D7"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MailIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '40'}
    height={props?.height || '40'}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="#5D42BD"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <title></title>{' '}
      <g id="Complete">
        {' '}
        <g id="mail">
          {' '}
          <g>
            {' '}
            <polyline
              fill="none"
              points="4 8.2 12 14.1 20 8.2"
              stroke="#5D42BD"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            ></polyline>{' '}
            <rect
              fill="none"
              height="14"
              rx="2"
              ry="2"
              stroke="#5D42BD"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              width="18"
              x="3"
              y="6.5"
            ></rect>{' '}
          </g>{' '}
        </g>{' '}
      </g>{' '}
    </g>
  </svg>
);

export const CorrectSuccessIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '38'}
    height={props?.height || '38'}
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.6666 17.4667V19C35.6645 22.594 34.5008 26.0911 32.3488 28.9697C30.1969 31.8483 27.1721 33.9541 23.7255 34.9732C20.279 35.9922 16.5954 35.8698 13.224 34.6243C9.85272 33.3788 6.97434 31.0768 5.01819 28.0618C3.06203 25.0467 2.1329 21.4801 2.36938 17.8939C2.60586 14.3076 3.99526 10.8939 6.33039 8.16179C8.66551 5.4297 11.8212 3.52564 15.3269 2.73357C18.8326 1.94151 22.5004 2.30389 25.7833 3.76667M35.6666 5.66667L18.9999 22.35L13.9999 17.35"
      stroke={props?.color || '#05603A'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UsersEmployeIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '18'}
    height={props?.height || '18'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <g clipPath="url(#clip0_1251_98416)">
        {' '}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 0C5.96243 0 3.5 2.46243 3.5 5.5C3.5 8.53757 5.96243 11 9 11C12.0376 11 14.5 8.53757 14.5 5.5C14.5 2.46243 12.0376 0 9 0ZM5.5 5.5C5.5 3.567 7.067 2 9 2C10.933 2 12.5 3.567 12.5 5.5C12.5 7.433 10.933 9 9 9C7.067 9 5.5 7.433 5.5 5.5Z"
          fill={props?.color || '#6D51D7'}
        ></path>{' '}
        <path
          d="M15.5 0C14.9477 0 14.5 0.447715 14.5 1C14.5 1.55228 14.9477 2 15.5 2C17.433 2 19 3.567 19 5.5C19 7.433 17.433 9 15.5 9C14.9477 9 14.5 9.44771 14.5 10C14.5 10.5523 14.9477 11 15.5 11C18.5376 11 21 8.53757 21 5.5C21 2.46243 18.5376 0 15.5 0Z"
          fill={props?.color || '#6D51D7'}
        ></path>{' '}
        <path
          d="M19.0837 14.0157C19.3048 13.5096 19.8943 13.2786 20.4004 13.4997C22.5174 14.4246 24 16.538 24 19V21C24 21.5523 23.5523 22 23 22C22.4477 22 22 21.5523 22 21V19C22 17.3613 21.0145 15.9505 19.5996 15.3324C19.0935 15.1113 18.8625 14.5217 19.0837 14.0157Z"
          fill={props?.color || '#6D51D7'}
        ></path>{' '}
        <path
          d="M6 13C2.68629 13 0 15.6863 0 19V21C0 21.5523 0.447715 22 1 22C1.55228 22 2 21.5523 2 21V19C2 16.7909 3.79086 15 6 15H12C14.2091 15 16 16.7909 16 19V21C16 21.5523 16.4477 22 17 22C17.5523 22 18 21.5523 18 21V19C18 15.6863 15.3137 13 12 13H6Z"
          fill={props?.color || '#6D51D7'}
        ></path>{' '}
      </g>{' '}
      <defs>
        {' '}
        <clipPath id="clip0_1251_98416">
          {' '}
          <rect width="24" height="24" fill="white"></rect>{' '}
        </clipPath>{' '}
      </defs>{' '}
    </g>
  </svg>
);

export const EditIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '26'}
    height={props?.height || '26'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M12 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V12M9 15V12.5L17.75 3.75C18.4404 3.05964 19.5596 3.05964 20.25 3.75V3.75C20.9404 4.44036 20.9404 5.55964 20.25 6.25L15.5 11L11.5 15H9Z"
        stroke={props?.color || '#000000'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const XCircleIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '20'}
    height={props?.height || '20'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M9.70711 8.29289C9.31658 7.90237 8.68342 7.90237 8.29289 8.29289C7.90237 8.68342 7.90237 9.31658 8.29289 9.70711L10.5858 12L8.29289 14.2929C7.90237 14.6834 7.90237 15.3166 8.29289 15.7071C8.68342 16.0976 9.31658 16.0976 9.70711 15.7071L12 13.4142L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L13.4142 12L15.7071 9.70711C16.0976 9.31658 16.0976 8.68342 15.7071 8.29289C15.3166 7.90237 14.6834 7.90237 14.2929 8.29289L12 10.5858L9.70711 8.29289Z"
        fill={props?.color || '#0F1729'}
      ></path>{' '}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12Z"
        fill={props?.color || '#0F1729'}
      ></path>{' '}
    </g>
  </svg>
);

export const CloseIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '20'}
    height={props?.height || '20'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
        fill="#0F0F0F"
      ></path>{' '}
    </g>
  </svg>
);

export const FileIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '18'}
    height={props?.height || '22'}
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V8M10 1L17 8M10 1V8H17"
      stroke={props.stroke || '#667085'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DeleteIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '24'}
    height={props?.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M6 7V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V7M6 7H5M6 7H8M18 7H19M18 7H16M10 11V16M14 11V16M8 7V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V7M8 7H16"
        stroke={props?.color || '#000000'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const PlusIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '24'}
    height={props?.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M4 12H20M12 4V20"
        stroke={props?.color || '#000000'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const FolderIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '24'}
    height={props?.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M3 8.2C3 7.07989 3 6.51984 3.21799 6.09202C3.40973 5.71569 3.71569 5.40973 4.09202 5.21799C4.51984 5 5.0799 5 6.2 5H9.67452C10.1637 5 10.4083 5 10.6385 5.05526C10.8425 5.10425 11.0376 5.18506 11.2166 5.29472C11.4184 5.4184 11.5914 5.59135 11.9373 5.93726L12.0627 6.06274C12.4086 6.40865 12.5816 6.5816 12.7834 6.70528C12.9624 6.81494 13.1575 6.89575 13.3615 6.94474C13.5917 7 13.8363 7 14.3255 7H17.8C18.9201 7 19.4802 7 19.908 7.21799C20.2843 7.40973 20.5903 7.71569 20.782 8.09202C21 8.51984 21 9.0799 21 10.2V15.8C21 16.9201 21 17.4802 20.782 17.908C20.5903 18.2843 20.2843 18.5903 19.908 18.782C19.4802 19 18.9201 19 17.8 19H6.2C5.07989 19 4.51984 19 4.09202 18.782C3.71569 18.5903 3.40973 18.2843 3.21799 17.908C3 17.4802 3 16.9201 3 15.8V8.2Z"
        stroke={props?.color || '#000000'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const OpsiIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '16'}
    height={props?.height || '16'}
    fill={props?.color || '#000000'}
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path d="M388.8 896.4v-27.198c.6-2.2 1.6-4.2 2-6.4 8.8-57.2 56.4-102.4 112.199-106.2 62.4-4.4 115.2 31.199 132.4 89.199 2.2 7.6 3.8 15.6 5.8 23.4v27.2c-.6 1.8-1.6 3.399-1.8 5.399-8.6 52.8-46.6 93-98.6 104.4-4 .8-8 2-12 3h-27.2c-1.8-.6-3.6-1.6-5.4-1.8-52-8.4-91.599-45.4-103.6-96.8-1.2-5-2.6-9.6-3.8-14.2zm252.4-768.797l-.001 27.202c-.6 2.2-1.6 4.2-1.8 6.4-9 57.6-56.8 102.6-113.2 106.2-62.2 4-114.8-32-131.8-90.2-2.2-7.401-3.8-15-5.6-22.401v-27.2c.6-1.8 1.6-3.4 2-5.2 9.6-52 39.8-86 90.2-102.2 6.6-2.2 13.6-3.4 20.4-5.2h27.2c1.8.6 3.6 1.6 5.4 1.8 52.2 8.6 91.6 45.4 103.6 96.8 1.201 4.8 2.401 9.4 3.601 13.999zm-.001 370.801v27.2c-.6 2.2-1.6 4.2-2 6.4-9 57.4-58.6 103.6-114.6 106-63 2.8-116.4-35.2-131.4-93.8-1.6-6.2-3-12.4-4.4-18.6v-27.2c.6-2.2 1.6-4.2 2-6.4 8.8-57.4 58.6-103.601 114.6-106.2 63-3 116.4 35.2 131.4 93.8 1.6 6.4 3 12.6 4.4 18.8z"></path>
    </g>
  </svg>
);

export const UnduhIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '18'}
    height={props?.height || '18'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
      <path
        d="M12 3V16M12 16L16 11.625M12 16L8 11.625"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const UploadIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '18'}
    height={props?.height || '18'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
      <path
        d="M12 16V3M12 3L16 7.375M12 3L8 7.375"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const ClipboardIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '18'}
    height={props?.height || '18'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M8 5.00005C7.01165 5.00082 6.49359 5.01338 6.09202 5.21799C5.71569 5.40973 5.40973 5.71569 5.21799 6.09202C5 6.51984 5 7.07989 5 8.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.07989 21 8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V8.2C19 7.07989 19 6.51984 18.782 6.09202C18.5903 5.71569 18.2843 5.40973 17.908 5.21799C17.5064 5.01338 16.9884 5.00082 16 5.00005M8 5.00005V7H16V5.00005M8 5.00005V4.70711C8 4.25435 8.17986 3.82014 8.5 3.5C8.82014 3.17986 9.25435 3 9.70711 3H14.2929C14.7456 3 15.1799 3.17986 15.5 3.5C15.8201 3.82014 16 4.25435 16 4.70711V5.00005"
        stroke="#6D51D7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const PieChartIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '18'}
    height={props?.height || '18'}
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
    fill="#EC4A0A"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path
        fill="#EC4A0A"
        d="M448 68.48v64.832A384.128 384.128 0 0 0 512 896a384.128 384.128 0 0 0 378.688-320h64.768A448.128 448.128 0 0 1 64 512 448.128 448.128 0 0 1 448 68.48z"
      ></path>
      <path
        fill="#EC4A0A"
        d="M576 97.28V448h350.72A384.064 384.064 0 0 0 576 97.28zM512 64V33.152A448 448 0 0 1 990.848 512H512V64z"
      ></path>
    </g>
  </svg>
);

export const LoadingIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '24'}
    height={props?.height || '24'}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    className="hds-flight-icon--animation-loading"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <g fill="#4f4f4f" fillRule="evenodd" clipRule="evenodd">
        {' '}
        <path
          d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8z"
          opacity=".2"
        ></path>{' '}
        <path d="M7.25.75A.75.75 0 018 0a8 8 0 018 8 .75.75 0 01-1.5 0A6.5 6.5 0 008 1.5a.75.75 0 01-.75-.75z"></path>{' '}
      </g>{' '}
    </g>
  </svg>
);

export const ManageOrgIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '22'}
    height={props?.height || '22'}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 19V3C15 2.46957 14.7893 1.96086 14.4142 1.58579C14.0391 1.21071 13.5304 1 13 1H9C8.46957 1 7.96086 1.21071 7.58579 1.58579C7.21071 1.96086 7 2.46957 7 3V19M3 5H19C20.1046 5 21 5.89543 21 7V17C21 18.1046 20.1046 19 19 19H3C1.89543 19 1 18.1046 1 17V7C1 5.89543 1.89543 5 3 5Z"
      stroke="#667085"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ManageOrgActiveIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '22'}
    height={props?.height || '22'}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 19V3C15 2.46957 14.7893 1.96086 14.4142 1.58579C14.0391 1.21071 13.5304 1 13 1H9C8.46957 1 7.96086 1.21071 7.58579 1.58579C7.21071 1.96086 7 2.46957 7 3V19M3 5H19C20.1046 5 21 5.89543 21 7V17C21 18.1046 20.1046 19 19 19H3C1.89543 19 1 18.1046 1 17V7C1 5.89543 1.89543 5 3 5Z"
      stroke="#6D51D7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LogIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '22'}
    height={props?.height || '22'}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 5V11L15 13M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
      stroke="#667085"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LogActiveIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '22'}
    height={props?.height || '22'}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 5V11L15 13M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
      stroke="#6D51D7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RegenaretIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '16'}
    height={props?.height || '14'}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.666504 1.66704V5.66704M0.666504 5.66704H4.6665M0.666504 5.66704L3.75984 2.76038C4.47634 2.04352 5.36275 1.51985 6.33638 1.23823C7.31 0.956603 8.3391 0.92621 9.32765 1.14988C10.3162 1.37355 11.232 1.844 11.9895 2.51732C12.7471 3.19064 13.3217 4.0449 13.6598 5.00038M15.3332 12.3337V8.33371M15.3332 8.33371H11.3332M15.3332 8.33371L12.2398 11.2404C11.5233 11.9572 10.6369 12.4809 9.6633 12.7625C8.68967 13.0441 7.66058 13.0745 6.67203 12.8509C5.68348 12.6272 4.7677 12.1568 4.01015 11.4834C3.25259 10.8101 2.67795 9.95585 2.33984 9.00038"
      stroke="#98A2B3"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CopyPasteIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '16'}
    height={props?.height || '16'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33398 9.9987H2.66732C2.3137 9.9987 1.97456 9.85822 1.72451 9.60817C1.47446 9.35813 1.33398 9.01899 1.33398 8.66536V2.66536C1.33398 2.31174 1.47446 1.9726 1.72451 1.72256C1.97456 1.47251 2.3137 1.33203 2.66732 1.33203H8.66732C9.02094 1.33203 9.36008 1.47251 9.61013 1.72256C9.86018 1.9726 10.0007 2.31174 10.0007 2.66536V3.33203M7.33398 5.9987H13.334C14.0704 5.9987 14.6673 6.59565 14.6673 7.33203V13.332C14.6673 14.0684 14.0704 14.6654 13.334 14.6654H7.33398C6.5976 14.6654 6.00065 14.0684 6.00065 13.332V7.33203C6.00065 6.59565 6.5976 5.9987 7.33398 5.9987Z"
      stroke={props?.color || '#98A2B3'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CorrectIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '16'}
    height={props?.height || '16'}
    fill={props?.color || '#000000'}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 335.765 335.765"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#CCCCCC"
      strokeWidth="0.67153"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <g>
        {' '}
        <g>
          {' '}
          <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795 "></polygon>{' '}
        </g>{' '}
      </g>{' '}
    </g>
  </svg>
);

export const ListIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '16'}
    height={props?.height || '16'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M8 6.00067L21 6.00139M8 12.0007L21 12.0015M8 18.0007L21 18.0015M3.5 6H3.51M3.5 12H3.51M3.5 18H3.51M4 6C4 6.27614 3.77614 6.5 3.5 6.5C3.22386 6.5 3 6.27614 3 6C3 5.72386 3.22386 5.5 3.5 5.5C3.77614 5.5 4 5.72386 4 6ZM4 12C4 12.2761 3.77614 12.5 3.5 12.5C3.22386 12.5 3 12.2761 3 12C3 11.7239 3.22386 11.5 3.5 11.5C3.77614 11.5 4 11.7239 4 12ZM4 18C4 18.2761 3.77614 18.5 3.5 18.5C3.22386 18.5 3 18.2761 3 18C3 17.7239 3.22386 17.5 3.5 17.5C3.77614 17.5 4 17.7239 4 18Z"
        stroke={props?.color || '#000000'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const SearchIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '16'}
    height={props?.height || '16'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M16.6725 16.6412L21 21M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const InformationsIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '23'}
    height={props?.height || '23'}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7448_2341)">
      <path
        d="M18.2596 6.23762C19.5978 7.9556 20.1988 10.1347 19.9306 12.2958C19.6624 14.4569 18.5468 16.4229 16.8292 17.7616M15.0256 8.75746C15.6947 9.61645 15.9952 10.706 15.8611 11.7866C15.727 12.8671 15.1692 13.8501 14.3104 14.5195M11.6755 5.47831L7.19592 8.2325L3.93594 7.82786L3.32899 12.7178L6.58896 13.1225L10.2593 16.8882L11.6755 5.47831Z"
        stroke="#039855"
        strokeWidth="1.53984"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7448_2341">
        <rect
          width="19.71"
          height="19.71"
          fill="white"
          transform="translate(3.21631 0.290588) rotate(7.07549)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const SummarizingIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '18'}
    height={props?.height || '14'}
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.02005 2.8188L15.6372 1.69735M5.53764 7.71903L16.1548 6.59758M6.05523 12.6193L16.6724 11.4978M0.936523 3.25012L0.94469 3.24926M1.45411 8.15035L1.46228 8.14949M1.9717 13.0506L1.97987 13.0497"
      stroke="#D92D20"
      strokeWidth="1.53984"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AssignIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '23'}
    height={props?.height || '24'}
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7448_2338)">
      <path
        d="M13.6682 19.6892L15.6259 6.69593C15.6908 6.26518 15.5819 5.82628 15.3232 5.4758C15.0645 5.12532 14.6772 4.89196 14.2465 4.82706L10.9981 4.33762C10.5674 4.27272 10.1285 4.3816 9.77799 4.64029C9.42751 4.89899 9.19415 5.28632 9.12924 5.71707L7.17152 18.7104M5.6362 6.85181L18.6295 8.80953C19.5265 8.94468 20.1441 9.78141 20.009 10.6784L18.7854 18.7992C18.6502 19.6962 17.8135 20.3138 16.9165 20.1787L3.92319 18.221C3.02619 18.0858 2.40859 17.2491 2.54374 16.3521L3.76732 8.23126C3.90247 7.33426 4.7392 6.71666 5.6362 6.85181Z"
        stroke="#6938EF"
        strokeWidth="1.53984"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7448_2338">
        <rect
          width="19.71"
          height="19.71"
          fill="white"
          transform="translate(3.24414 0.677734) rotate(8.56838)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const CekCircleIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '19'}
    height={props?.height || '19'}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0269 7.09249L17.1832 7.83169C17.5486 9.56454 17.3441 11.3691 16.6001 12.9762C15.8562 14.5833 14.6126 15.9069 13.055 16.7495C11.4973 17.5921 9.70902 17.9086 7.95677 17.6519C6.20452 17.3951 4.58222 16.5788 3.33181 15.3247C2.0814 14.0706 1.26988 12.4459 1.01828 10.6929C0.766684 8.93992 1.08849 7.15256 1.9357 5.5974C2.78291 4.04224 4.11014 2.8026 5.71943 2.06337C7.32873 1.32414 9.13387 1.12493 10.8656 1.49544M15.824 1.40385L9.48995 11.1458L6.56979 9.24505"
      stroke="#DC6803"
      strokeWidth="1.53984"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ContactIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '24'}
    height={props?.height || '25'}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7448_2353)">
      <path
        d="M14.5712 20.6773L14.9036 19.0688C15.08 18.2156 14.9102 17.3273 14.4316 16.5993C13.953 15.8713 13.2048 15.3633 12.3516 15.1869L5.91756 13.8571C5.06436 13.6807 4.17605 13.8505 3.44806 14.3291C2.72006 14.8078 2.212 15.556 2.03565 16.4092L1.7032 18.0177M19.3967 21.6747L19.7291 20.0662C19.8759 19.3533 19.7818 18.6118 19.4615 17.9582C19.1413 17.3046 18.613 16.7759 17.9597 16.4551M16.7374 6.13915C17.3928 6.45935 17.923 6.98857 18.2443 7.64337C18.5657 8.29816 18.66 9.0413 18.5124 9.75561C18.3647 10.4699 17.9835 11.1148 17.4289 11.5885C16.8743 12.0623 16.1778 12.3379 15.4492 12.3721M13.6814 8.75291C13.3142 10.5296 11.5762 11.6722 9.79948 11.305C8.02278 10.9378 6.88017 9.19978 7.24739 7.42308C7.61462 5.64638 9.35261 4.50378 11.1293 4.871C12.906 5.23822 14.0486 6.97621 13.6814 8.75291Z"
        stroke="#E31B54"
        strokeWidth="1.53984"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7448_2353">
        <rect
          width="19.71"
          height="19.71"
          fill="white"
          transform="translate(4.38965 0.962219) rotate(11.6779)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const CalenderIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '19'}
    height={props?.height || '20'}
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2647 1.11689L11.821 4.35442M4.78959 2.22968L5.34599 5.46721M1.85547 9.40023L16.4244 6.89645M2.63964 4.26574L13.971 2.31835C14.865 2.16471 15.7143 2.7649 15.868 3.65892L17.8153 14.9903C17.969 15.8843 17.3688 16.7336 16.4748 16.8872L5.14342 18.8346C4.2494 18.9883 3.4001 18.3881 3.24646 17.4941L1.29907 6.1627C1.14543 5.26868 1.74562 4.41938 2.63964 4.26574Z"
      stroke="#DD2590"
      strokeWidth="1.53984"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ReportIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '18'}
    height={props?.height || '20'}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7047 4.00554L15.3226 4.28887C15.7516 4.36401 16.1333 4.60653 16.3836 4.96308C16.6339 5.31962 16.7322 5.76099 16.6571 6.19007L14.6738 17.5152C14.5986 17.9443 14.3561 18.3259 13.9996 18.5762C13.643 18.8265 13.2017 18.9249 12.7726 18.8497L3.06534 17.1498C2.63625 17.0746 2.25459 16.8321 2.00431 16.4756C1.75404 16.119 1.65565 15.6776 1.73079 15.2486L3.7141 3.92343C3.78925 3.49435 4.03177 3.11268 4.38831 2.86241C4.74486 2.61213 5.18622 2.51374 5.61531 2.58889L7.23318 2.87222M8.32545 1.39601L13.1791 2.246C13.6258 2.32424 13.9246 2.74984 13.8463 3.1966L13.563 4.81448C13.4848 5.26124 13.0592 5.55999 12.6124 5.48175L7.75879 4.63176C7.31203 4.55352 7.01328 4.12792 7.09152 3.68116L7.37485 2.06328C7.45309 1.61652 7.87869 1.31777 8.32545 1.39601Z"
      stroke="#0086C9"
      strokeWidth="1.53984"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const StopMessageIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '24'}
    height={props?.height || '24'}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="28"
      height="28"
      rx="14"
      transform="matrix(0 -1 -1 0 28 28)"
      fill="#4A3695"
    />
    <rect x="8" y="8" width="12" height="12" rx="2" fill="white" />
  </svg>
);

export const DeleteMemoryLogIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '20'}
    height={props?.height || '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 5.00008H4.16667M4.16667 5.00008H17.5M4.16667 5.00008V16.6667C4.16667 17.1088 4.34226 17.5327 4.65482 17.8453C4.96738 18.1578 5.39131 18.3334 5.83333 18.3334H14.1667C14.6087 18.3334 15.0326 18.1578 15.3452 17.8453C15.6577 17.5327 15.8333 17.1088 15.8333 16.6667V5.00008H4.16667ZM6.66667 5.00008V3.33341C6.66667 2.89139 6.84226 2.46746 7.15482 2.1549C7.46738 1.84234 7.89131 1.66675 8.33333 1.66675H11.6667C12.1087 1.66675 12.5326 1.84234 12.8452 2.1549C13.1577 2.46746 13.3333 2.89139 13.3333 3.33341V5.00008"
      stroke={props?.color || '#98A2B3'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EditMemoryLogIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '20'}
    height={props?.height || '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7849_52587)">
      <path
        d="M9.16699 3.33344H3.33366C2.89163 3.33344 2.46771 3.50904 2.15515 3.8216C1.84259 4.13416 1.66699 4.55808 1.66699 5.00011V16.6668C1.66699 17.1088 1.84259 17.5327 2.15515 17.8453C2.46771 18.1578 2.89163 18.3334 3.33366 18.3334H15.0003C15.4424 18.3334 15.8663 18.1578 16.1788 17.8453C16.4914 17.5327 16.667 17.1088 16.667 16.6668V10.8334M15.417 2.08344C15.7485 1.75192 16.1982 1.56567 16.667 1.56567C17.1358 1.56567 17.5855 1.75192 17.917 2.08344C18.2485 2.41496 18.4348 2.8646 18.4348 3.33344C18.4348 3.80228 18.2485 4.25192 17.917 4.58344L10.0003 12.5001L6.66699 13.3334L7.50033 10.0001L15.417 2.08344Z"
        stroke="#98A2B3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7849_52587">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const DotHorizontalIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '20'}
    height={props?.height || '20'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M18 12H18.01M12 12H12.01M6 12H6.01M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12ZM7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12Z"
        stroke="#5D42BD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

export const ShareIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '20'}
    height={props?.height || '20'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5 2.25C14.7051 2.25 13.25 3.70507 13.25 5.5C13.25 5.69591 13.2673 5.88776 13.3006 6.07412L8.56991 9.38558C8.54587 9.4024 8.52312 9.42038 8.50168 9.43939C7.94993 9.00747 7.25503 8.75 6.5 8.75C4.70507 8.75 3.25 10.2051 3.25 12C3.25 13.7949 4.70507 15.25 6.5 15.25C7.25503 15.25 7.94993 14.9925 8.50168 14.5606C8.52312 14.5796 8.54587 14.5976 8.56991 14.6144L13.3006 17.9259C13.2673 18.1122 13.25 18.3041 13.25 18.5C13.25 20.2949 14.7051 21.75 16.5 21.75C18.2949 21.75 19.75 20.2949 19.75 18.5C19.75 16.7051 18.2949 15.25 16.5 15.25C15.4472 15.25 14.5113 15.7506 13.9174 16.5267L9.43806 13.3911C9.63809 12.9694 9.75 12.4978 9.75 12C9.75 11.5022 9.63809 11.0306 9.43806 10.6089L13.9174 7.4733C14.5113 8.24942 15.4472 8.75 16.5 8.75C18.2949 8.75 19.75 7.29493 19.75 5.5C19.75 3.70507 18.2949 2.25 16.5 2.25ZM14.75 5.5C14.75 4.5335 15.5335 3.75 16.5 3.75C17.4665 3.75 18.25 4.5335 18.25 5.5C18.25 6.4665 17.4665 7.25 16.5 7.25C15.5335 7.25 14.75 6.4665 14.75 5.5ZM6.5 10.25C5.5335 10.25 4.75 11.0335 4.75 12C4.75 12.9665 5.5335 13.75 6.5 13.75C7.4665 13.75 8.25 12.9665 8.25 12C8.25 11.0335 7.4665 10.25 6.5 10.25ZM16.5 16.75C15.5335 16.75 14.75 17.5335 14.75 18.5C14.75 19.4665 15.5335 20.25 16.5 20.25C17.4665 20.25 18.25 19.4665 18.25 18.5C18.25 17.5335 17.4665 16.75 16.5 16.75Z"
        fill="#000000"
      ></path>{' '}
    </g>
  </svg>
);

export const LinkShareIcon = (props) => (
  <svg
    {...props}
    width={props?.width || '20'}
    height={props?.height || '20'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M16.4437 2.00021C14.9719 1.98733 13.5552 2.55719 12.4986 3.58488L12.4883 3.59504L11.6962 4.38801C11.3059 4.77876 11.3063 5.41192 11.697 5.80222C12.0878 6.19252 12.721 6.19216 13.1113 5.80141L13.8979 5.01386C14.5777 4.35511 15.4855 3.99191 16.4262 4.00014C17.3692 4.00839 18.2727 4.38923 18.9416 5.06286C19.6108 5.73671 19.9916 6.64971 19.9998 7.6056C20.008 8.55874 19.6452 9.47581 18.9912 10.1607L16.2346 12.9367C15.8688 13.3052 15.429 13.5897 14.9453 13.7714C14.4616 13.9531 13.945 14.0279 13.4304 13.9907C12.9159 13.9536 12.4149 13.8055 11.9616 13.5561C11.5083 13.3067 11.1129 12.9617 10.8027 12.5441C10.4734 12.1007 9.847 12.0083 9.40364 12.3376C8.96029 12.6669 8.86785 13.2933 9.19718 13.7367C9.67803 14.384 10.2919 14.9202 10.9975 15.3084C11.7032 15.6966 12.4838 15.9277 13.2866 15.9856C14.0893 16.0435 14.8949 15.9268 15.6486 15.6437C16.4022 15.3606 17.0861 14.9177 17.654 14.3457L20.4168 11.5635L20.429 11.551C21.4491 10.4874 22.0125 9.0642 21.9997 7.58834C21.987 6.11247 21.3992 4.69931 20.3607 3.65359C19.3221 2.60764 17.9155 2.01309 16.4437 2.00021Z"
        fill={props?.color || '#000000'}
      ></path>{' '}
      <path
        d="M10.7134 8.01444C9.91064 7.95655 9.10506 8.0732 8.35137 8.35632C7.59775 8.63941 6.91382 9.08232 6.34597 9.65431L3.5831 12.4365L3.57097 12.449C2.5508 13.5126 1.98748 14.9358 2.00021 16.4117C2.01295 17.8875 2.60076 19.3007 3.6392 20.3464C4.67787 21.3924 6.08439 21.9869 7.55623 21.9998C9.02807 22.0127 10.4447 21.4428 11.5014 20.4151L11.5137 20.4029L12.3012 19.6099C12.6903 19.218 12.6881 18.5849 12.2962 18.1957C11.9043 17.8066 11.2712 17.8088 10.882 18.2007L10.1011 18.9871C9.42133 19.6452 8.51402 20.0081 7.57373 19.9999C6.63074 19.9916 5.72728 19.6108 5.05834 18.9371C4.38918 18.2633 4.00839 17.3503 4.00014 16.3944C3.99191 15.4412 4.35479 14.5242 5.00874 13.8393L7.76537 11.0633C8.13118 10.6948 8.57097 10.4103 9.05467 10.2286C9.53836 10.0469 10.0549 9.97215 10.5695 10.0093C11.0841 10.0464 11.585 10.1945 12.0383 10.4439C12.4917 10.6933 12.887 11.0383 13.1972 11.4559C13.5266 11.8993 14.1529 11.9917 14.5963 11.6624C15.0397 11.3331 15.1321 10.7067 14.8028 10.2633C14.3219 9.61599 13.708 9.07982 13.0024 8.69161C12.2968 8.30338 11.5161 8.07233 10.7134 8.01444Z"
        fill={props?.color || '#000000'}
      ></path>{' '}
    </g>
  </svg>
);
